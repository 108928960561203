import { Card, CardContent, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";

const individualGradientTextStyle = {
    fontWeight: 'bold',
    background: '-webkit-linear-gradient(260deg, #007bff 0%, #000 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    textAlign: 'center',
};

const imageGalleryTitle = 'The Minds Behind';

const imageData = [
    {
        src: '/assets/images/about/employees/philipp.webp',
        name: 'Philipp Dewald',
        title: 'CEO',
    },
    {
        src: '/assets/images/about/employees/peter.webp',
        name: 'Peter Stolz',
        title: 'CTO',
    },
    {
        src: '/assets/images/about/employees/tim.webp',
        name: 'Tim Walita',
        title: 'COO',
    },
    {
        src: '/assets/images/about/employees/shreyash.webp',
        name: 'Shreyash Arya',
        title: 'AI Engineer',

    },
    {
        src: '/assets/images/about/employees/devikalyan.webp',
        name: 'Devikalyan Das',
        title: 'AI Engineer',
    },
    {
        src: '/assets/images/about/employees/noshaba.webp',
        name: 'Noshaba Cheema',
        title: 'Research Assistant',
    },
    {
        src: '/assets/images/about/employees/naufil.webp',
        name: 'Muhammad Naufil',
        title: 'Research Assistant',
    },
];

ImageCard.propTypes = {
    src: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};

function ImageCard({ src, title, name, description }) {
    return (
        <Card>
            <img src={src} alt={description} style={{ width: '100%', height: 'auto' }} />
            <CardContent>
                <Typography variant="h6" style={{ textAlign: 'center' }}>
                    {name}
                </Typography>
                <Typography variant="subtitle1" style={{ fontWeight: 'bold', textAlign: 'center' }}>
                    {title}
                </Typography>
                <Typography style={{ textAlign: 'center' }}>{description}</Typography>
            </CardContent>
        </Card>
    );
}


export default function Team() {
    return (
        <>
            <Typography variant="h2" style={individualGradientTextStyle}>
                {imageGalleryTitle}
            </Typography>
            <Grid container spacing={4} style={{ paddingTop: '20px' }}>
                {imageData.map((item, index) => (
                    <Grid item xs={12} sm={4} key={index}>
                        <ImageCard
                            src={item.src}
                            title={item.title}
                            name={item.name}
                            description={item.description}
                        />
                    </Grid>
                ))}
            </Grid>
        </>
    );
}
