import { Grid, Tooltip, Typography } from '@mui/material';
import Image from '../../../components/image';

export default function Partners() {
  const affiliations = {
    'https://cispa.de/en': [
      '/assets/images/home/affiliations/cispa.webp',
      'CISPA Helmholtz Center for Information Security, Germany',
    ],
    'https://saarland-informatics-campus.de/': [
      '/assets/images/home/affiliations/sic.webp',
      'Saarland Informatics Campus, Germany',
    ],
    'https://www.uni-saarland.de/start.html': [
      '/assets/images/home/affiliations/uds.webp',
      'Saarland University, Germany',
    ],
    'https://www.bmbf.de/bmbf/en/home/home_node.html': [
      '/assets/images/home/affiliations/bmbf.webp',
      'Federal Ministry of Education and Research, Germany',
    ],
    'https://www.uds-triathlon.de/': [
      '/assets/images/home/affiliations/triathlon.webp',
      'Triathlon Ecosystem, Germany',
    ],
    'https://www.klein-public.de/': [
      '/assets/images/home/affiliations/klein-public.webp',
      'klein public advisory, Germany',
    ],
  };

  return (
    <Grid container spacing={8} sx={{ justifyContent: 'center', alignItems: 'center' }}>
      <Grid
        item
        md={4}
        xs={12}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Typography
          variant="h2"
          sx={{
            textAlign: 'center',
            marginY: 'auto',
            fontWeight: 'bold',
            background: (theme) =>
              `-webkit-linear-gradient(260deg, ${theme.palette.primary.main} 0%, ${theme.palette.grey[800]} 100%)`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          Our Partners
        </Typography>
      </Grid>
      <Grid item md={7} xs={12}>
        <Grid container spacing={4}>
          {Object.entries(affiliations).map(([key, value]) => (
            <Grid item xs={4} key={key} sx={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip title={value[1]}>
                <Image
                  alt={key}
                  src={value[0]}
                  sx={{
                    height: 'auto',
                    width: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    objectFit: 'contain',
                    // make image grayscale
                    // filter: 'grayscale(100%)',
                    '&:hover': {
                      // filter: 'grayscale(0%)',
                      transform: 'scale(1.1)',
                    },
                  }}
                />
              </Tooltip>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
