import { Container, Link, Typography } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import { paths } from '../routes/paths';

export default function LegalNotice() {
  return (
    <>
      <Helmet>
        <title> Impressum | Detesia </title>
      </Helmet>
      <Container textAlign="center">
        <Typography variant="h1" sx={{ mt: 5, fontWeight: 'fontWeightMedium' }}>
          Impressum
        </Typography>

        <Typography variant="h4" sx={{ mt: 5 }}>
          Anbieter dieses Internetangebots
        </Typography>
        <Typography variant="body1" sx={{ mt: 3 }}>
          CISPA – Helmholtz-Zentrum für Informationssicherheit gGmbH
          <br />
          vertreten durch den Direktor und wissenschaftlichen Geschäftsführer Prof. Dr. Dr. h. c. Michael Backes
          <br />
          und den administrativen Geschäftsführer Dr. Kevin Streit
          <br />
          Stuhlsatzenhaus 5
          <br />
          66123 Saarbrücken, Deutschland
          <br />
          Fon: +49 681 87083 1001
          <br />
          Fax: +49 681 87083 8801
          <br />
          Internet:{' '}
          <Link variant="body1" href="https://www.cispa.de">
            www.cispa.de
          </Link>
        </Typography>
        <Typography>
          Vorsitzende:r des Aufsichtsrates: N.N.
        </Typography>

        <Typography variant="h4" sx={{ mt: 5 }}>
          Kontaktaufnahme
        </Typography>
        <Typography variant="body1" sx={{ mt: 3 }}>
          contact[at]detesia.com
        </Typography>
        <Link variant="body1" to={paths.contact} component={RouterLink}>
          Kontaktformular
        </Link>

        <Typography variant="h4" sx={{ mt: 5 }}>
          Handelsregister
        </Typography>
        <Typography variant="body1" sx={{ mt: 3 }}>
          Amtsgericht Saarbrücken, HRB 104390
        </Typography>

        <Typography variant="h4" sx={{ mt: 5 }}>
          Umsatzsteuer-ID-Nr.
        </Typography>
        <Typography variant="body1" sx={{ mt: 3 }}>
          USt-IdNr: DE316283105
        </Typography>

        <Typography variant="h4" sx={{ mt: 5 }}>
          Inhaltlich Verantwortlicher gemäß § 18 Abs. 2 MStV
        </Typography>
        <Typography variant="body1" sx={{ mt: 3, mb: 5 }}>
          Philipp Dewald
          <br />
          CISPA - Helmholtz-Zentrum für Informationssicherheit gGmbH
          <br />
          Stuhlsatzenhaus 5
          <br />
          66123 Saarbrücken, Deutschland
        </Typography>

        <Typography variant="h4" sx={{ mt: 5 }}>
          Haftung für Inhalte
        </Typography>
        <Typography variant="body1" sx={{ mt: 3, mb: 5 }}>
          Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach
          den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter
          jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
          überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
          hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach
          den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch
          erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
          Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
          entfernen.
        </Typography>

      </Container>
    </>
  );
}
